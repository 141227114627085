.map-leads .info-icon {
    /* height: 20px;
    width: 20px; */
    font-size: 20px;
}

.map-leads .info-icon {
    flex: 0 0 auto !important;
    margin-right: 13px;
}
.map-leads .info-icon svg {
    height: 20px !important;
}

.map-leads .info-icon g,
.map-leads .info-icon path {
    fill: #0046FF;
}