.form-control.withdrawn {
    background: #FFEBF1 0% 0% no-repeat padding-box;
    border: 1px solid #FD4189;
    border-radius: 5px;
}

.form-control.for_sale {
    background: #F6F7FD 0% 0% no-repeat padding-box;
    border: 1px solid #0046FF;
    border-radius: 5px;
}