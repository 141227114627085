.form-control.closed {
    background: #FFEBF1 0% 0% no-repeat padding-box !important;
    border: 1px solid #FD4189 !important;
    border-radius: 5px;
}

.form-control.open {
    background: #F6F7FD 0% 0% no-repeat padding-box !important;
    border: 1px solid #0046FF !important;
    border-radius: 5px;
}