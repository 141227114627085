.modal-backdrop {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    width: auto !important;
    top: 0 !important;
    bottom: 0;
}
.spinner {
    position: absolute;
    left: 50%;
    bottom: 50%;
    z-index: 100003;
}