.kt-aside-menu .kt-menu__nav > .kt-menu__section {
    margin: 18px 0 0 0;
    height: auto !important;
}

.kt-menu__link  {
    align-items: baseline !important;
}

.kt-menu__link-icon {
    background: #C9D1FE;
    height: 19px;
    width: 19px;
    border-radius: 3px;
    flex: 0 0 auto !important;
    margin-right: 5px;
}
.kt-menu__link-icon svg {
    height: 9px !important;
}

.kt-menu__link-icon g,
.kt-menu__link-icon path {
    fill: #0046FF;
}

.kt-menu__item--active .kt-menu__link-icon {
    background: #0046FF;
}

.kt-menu__item--active .kt-menu__link-icon g ,
.kt-menu__item--active .kt-menu__link-icon path {
    fill: white !important;
}

.kt-menu__link .kt-menu__link-text {
    font-size: 14px !important;
}