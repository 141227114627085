.base-table .search-btn {
    margin-left: 15px;
    padding: 8px !important;
    line-height: 16px !important;
    width: 104px;
}

.base-table .dataTables_filter {
    margin-bottom: 15px !important;
} 

.base-table .dataTables_filter {
    display: flex;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    font-weight: normal !important;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 18px 7px !important
}

.ReactTable button { 
    font-size: 16px;
    padding: 3px;
    font-weight: normal !important;
}

.ReactTable select.form-control {
    padding: 7px !important
}