.finance-application .info-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
}

.finance-application .info-icon {
    flex: 0 0 auto !important;
    margin-right: 13px;
}
.finance-application .info-icon svg {
    height: 20px !important;
}

.finance-application .info-icon g,
.finance-application .info-icon path {
    fill: #0046FF;
}

.finance-application .contactbtn {
    margin-left: 24px;
    border-radius: 5px;
}

