.vehicle-model .header {
    text-align: left;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #45454B;
    margin-bottom: 14px;
}

.vehicle-model .modal-dialog {
    margin: auto;
}

.vehicle-model .modal-content {
    width: 560px;
    max-width: 100%;
    padding: 32px 42px;
    box-shadow: 0px 0px 30px #00000029;
    border-radius: 10px !important;
    /* background: transparent; */
}

.modal.show .modal-dialog {
    width: 560px;
    max-width: 100%;
}

.vehicle-model .modal-body .info {
    color: #45454B;
    font-size: 14px;
    line-height: 25px;
    font-weight: normal;
}

.vehicle-model .modal-body .info .title {
    font-weight: 600;    
}

.vehicle-model .modal-body .stats {
    padding: 5px 0;
    border-bottom: 1px solid #EBEDF2;
}

.vehicle-model .modal-body .info-wrapper {
    border-bottom: 1px solid #EBEDF2;
    padding-bottom: 20px;
}

.vehicle-model .modal-body .stats span {
    text-align: left;
    letter-spacing: 0px;
    color: #45454B;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
}

.vehicle-model .modal-body .small-stats span {
    text-align: left;
    letter-spacing: 0px;
    color: #45454B;
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
}

.vehicle-model .modal-body .value {
    text-align: right;
}

.vehicle-model .modal-body .value span {
    font-size: 16px;
    line-height:25px;
    letter-spacing: 0px;
    color: #FD4189;
    opacity: 1;
}

.vehicle-model .modal-body .action {
    background: #0046FF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    float: right;
    margin-top: 40px;
}

.vehicle-model .modal-body .action button {
    text-align: center;
    width: 100px;
}

.modal-body section {
    margin-top: 8px;
}