
/*Custom Classes*/
.odondo-badge img {
  max-width: 150px!important;
  height: 150px!important;
}
.campaign-type {
  font-size: 10px;
  color: #a7abc3;
  font-weight: 500;
}
.scrolling {
  overflow-y: scroll;
  height: 80vh;
}

.header-title {
  margin-top: 20px;
}

.svg-success {
  fill: #0abb87 !important
}

.complete-goal {
  background: #f7f7f7;
}

#action-bar {
  position: fixed;
  top: 120px;
  right: 0px;
  height: 100%;
  width: 27%;
}

.font-thin {
  font-weight: 200;
}

.test-option:active {
  background-color: blue;
}

.your-rank {
  background-color: rgba(255, 184, 34, 0.1);
  padding: 10px!important;
  border-radius: 12px;
  margin-top: 10px;
  margin-left: -10px;
  margin-right: -10px;
}


/*Form Wizard*/

.swal2-popup .swal2-styled.swal2-cancel {
  background: none;
  border: 1px solid #222;
  color: #222;
}

.campaign-title {
  margin-bottom: 20px;
  text-align: center;
}
.campaign-col {
  display: flex;
}

.campaign-content {
  margin: 0 auto;
}


.mt-0 {
  margin-top: 0 !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}

/*Margin Classes*/
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form {
  width: 100%!important;
}


.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content {
  padding-left: 0px;
}

.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content {
  width: 100%
}

.disable-view {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #FFF;
  opacity:  0.8;
}

/** Start TABLE Styles **/
.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
  background: #0046FF
}

.rt-td {
  color: #6c7293;
  padding: 0;
}

.rt-tr-group:hover {
  background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
  box-shadow: none !important;
}

.-sort-asc .rt-resizer::before {
  padding-top: 10px;
  content: "▲";
  color: #0046FF;
}
.-sort-desc .rt-resizer::before {
  content: "▼";
  color: #0046FF;
  vertical-align: 'center'
}

.Table__page .select-page:hover {
  background-color: #0046FF;
}
/** End TABLE Styles **/

/** adverts preview **/
.advert_preview_card_stack {
  width: 380px;
  height: 536px;
  padding: 20px;
  border-radius: 20px;
  position: relative;
}

.advert_preview_background {
  background-size: cover !important
}

.advert_preview_card_stack .advert_preview_title {
  margin-top: 140px;
  font-size: 45px;
  font-weight: 600;
  max-width: 250px;
}

.advert_preview_card_stack .advert_preview_cta {
  font-size: 18px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  border: none;
  margin-top: 10px;
}

.advert_preview_card_stack .advert_preview_logo {
  position: absolute;
  bottom: 20px;
  font-size: 18px;
  height: 50px;
  right: 20px;
}

.advert_preview_banner {
  margin-top: 20px;
  width: 380px;
  height: 83px;
  padding-left: 24px;
  padding-top: 12px;
  position: relative;
}

.advert_preview_banner .advert_preview_title {
  font-size: 24px;
  font-weight: 500;
}

.advert_preview_banner .advert_preview_cta {
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: none;
}

.advert_preview_banner .advert_preview_logo {
  position: absolute;
  bottom: 8px;
  font-size: 18px;
  height: 32px;
  right: 20px;
}


/** Start CHAT styles **/

.chat-list {
  height: calc(100vh - 400px) !important;
}

.chat-message,
.chat-list {
  width: 100%;
  margin-bottom: 30px !important;
}

.chat-message-body {
  background-color: rgba(93, 120, 255, 0.1);
  color: #6c7293;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
}

.chat-input {
  padding: 10px;
  background-color: #FDFDFD !important;
  border-radius: 5px;
  border: 1px solid !important;
  border-style: solid !important;
  border-width: 1px;
  border-color: #646c9a;
  color: #646c9a;
}

.chat-input-container {
  padding: 20px;
  padding-right: 0;
}

.call-modal .chat-input-container {
  padding: 0px;
  padding-top: 20px;
}

.chat-send-button {
  margin-top: 5px !important;
}

.call-modal .chat-send-button {
  margin-right: -20px;
}

.chat-send-button polygon {
  fill: #646c9a !important;
}

.is-sender {
  padding-right: 5px;
  padding-left: 20%;
}

.is-recipient {
  padding-left: 5px;
  padding-right: 20%;
}

.is-recipient .chat-message-body {
  background-color: rgba(10, 187, 135, 0.1);
}
/** End CHAT styles **/

.kt-widget24{
  flex : 1 1 auto;
}

@media (max-width: 1024px) {
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span,
   .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::before,
   .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::after {
    background: #0046FF;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler i {
    color: #0046FF;
  }
}