
.chat-head-holder {
  margin: 0 10.5px !important;
  padding: 0 14.5px !important;
}

.chat-head-container {
  align-items: flex-start !important;
}

.chat-button-holder {
  margin-top: 1.6rem;
}

.kt-chat__left > h6 {
  font-size: 1.077rem;
}

.chat-header-name {
  font-size: 1.845rem;
  margin-top: 1.8rem;
  margin-bottom: 0px;
}

.chat-header-car-info {
  margin-top: 0.77rem;
  margin-bottom: 1rem;
}

.chat-header-email {
  margin-top: 1rem;
  margin-bottom: 0.6rem;
}

.chat-header-phone {
  margin-top: 0.6rem;
  margin-bottom: 1rem;
}

.chat-list {
  scroll-behavior: smooth;
}
.chat-list > div, .chat-list > div > div, .chat-list > div > div > div {
  margin-bottom: 0 !important;
}