.overview-cards select.month-select {
    background-color: #F6F7FD;
    border: 1px solid #0046FF;
    border-radius: 5px;
    color: #0046FF;
    font-size: 16px;
    line-height: 21px;
    padding: 4px 12px;
    width: 100%;
    min-width: 115px;
}