@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.secondary-txt {
  color: #FD4189;
}

.primary-txt {
  color: #0046FF;
}

.btn.btn-secondary {
  background-color: #FD4189 !important;
  color: white !important;
  border-color: #FD4189 !important;
}

.btn.btn-primary {
  background-color: #0046FF;
}

.btn {
  border-radius: 5px !important;
}


body.modal-open {
  overflow: hidden !important;
}
.react-toggle--checked .react-toggle-track {
  background-color: #0046FF !important;
}

.badge-secondary  {
  background-color: #FD4189 !important;
  color: #fff !important;
}