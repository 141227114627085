.stat-card.link {
    cursor: pointer;
}
.stat-card .popover-body {
    background: #C9D1FE;
    color: #45454B !important;
    border: none !important;
}

.stat-card .arrow::after, .arrow::before {
    border-bottom-color: #C9D1FE !important;
    border-top-color: #C9D1FE !important;
}

.stat-card .popover {
    border: none !important;
}

.stat-card .kt-widget24 { 
    padding: 15px 24px;
}
